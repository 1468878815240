import React, { useState } from 'react';
import { X, Eye, EyeOff, Mail } from 'lucide-react';
import { motion } from 'framer-motion';
import { User, ROLE_LABELS } from '../../types/user';
import { useUsers } from '../../hooks/useUsers';
import toast from 'react-hot-toast';

interface CreateUserModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function CreateUserModal({ isOpen, onClose }: CreateUserModalProps) {
  const { createUser } = useUsers();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState<User['role']>('AGENT');
  const [showPassword, setShowPassword] = useState(false);
  const [createMode, setCreateMode] = useState<'password' | 'invitation'>('invitation');
  const [isLoading, setIsLoading] = useState(false);

  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (!firstName.trim() || !lastName.trim()) {
        throw new Error('Le nom et le prénom sont requis');
      }

      const userData: Omit<User, 'id' | 'dateCreated'> = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        password: createMode === 'password' ? password : '',
        role,
        isActive: true
      };

      await createUser(userData);
      onClose();
      
      // Reset form
      setFirstName('');
      setLastName('');
      setEmail('');
      setPassword('');
      setRole('AGENT');
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-white rounded-xl w-full max-w-lg mx-4 shadow-xl"
      >
        <div className="p-4 border-b flex items-center justify-between">
          <h2 className="text-lg font-semibold text-gray-900">
            Nouvel utilisateur
          </h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
          >
            <X className="h-5 w-5 text-gray-500" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Prénom <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-[#2665EB] focus:border-[#2665EB] outline-none"
                placeholder="Jean"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Nom <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-[#2665EB] focus:border-[#2665EB] outline-none"
                placeholder="Dupont"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-[#2665EB] focus:border-[#2665EB] outline-none"
              placeholder="email@exemple.com"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Rôle <span className="text-red-500">*</span>
            </label>
            <select
              value={role}
              onChange={(e) => setRole(e.target.value as User['role'])}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-[#2665EB] focus:border-[#2665EB] outline-none"
            >
              {Object.entries(ROLE_LABELS).map(([value, label]) => (
                <option key={value} value={value}>{label}</option>
              ))}
            </select>
          </div>

          <div className="space-y-4">
            <label className="block text-sm font-medium text-gray-700">
              Mode de création
            </label>
            
            <div className="grid grid-cols-2 gap-4">
              <button
                type="button"
                onClick={() => setCreateMode('invitation')}
                className={`p-4 border rounded-lg text-left transition-colors ${
                  createMode === 'invitation'
                    ? 'border-[#2665EB] bg-[#2665EB]/5'
                    : 'hover:bg-gray-50'
                }`}
              >
                <Mail className={`h-5 w-5 mb-2 ${
                  createMode === 'invitation' ? 'text-[#2665EB]' : 'text-gray-400'
                }`} />
                <div className={`font-medium mb-1 ${
                  createMode === 'invitation' ? 'text-[#2665EB]' : 'text-gray-700'
                }`}>
                  Invitation par email
                </div>
                <p className="text-sm text-gray-500">
                  L'utilisateur recevra un email pour définir son mot de passe
                </p>
              </button>

              <button
                type="button"
                onClick={() => setCreateMode('password')}
                className={`p-4 border rounded-lg text-left transition-colors ${
                  createMode === 'password'
                    ? 'border-[#2665EB] bg-[#2665EB]/5'
                    : 'hover:bg-gray-50'
                }`}
              >
                <Eye className={`h-5 w-5 mb-2 ${
                  createMode === 'password' ? 'text-[#2665EB]' : 'text-gray-400'
                }`} />
                <div className={`font-medium mb-1 ${
                  createMode === 'password' ? 'text-[#2665EB]' : 'text-gray-700'
                }`}>
                  Définir le mot de passe
                </div>
                <p className="text-sm text-gray-500">
                  Créer le compte avec un mot de passe prédéfini
                </p>
              </button>
            </div>
          </div>

          {createMode === 'password' && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Mot de passe <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  minLength={6}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-[#2665EB] focus:border-[#2665EB] outline-none pr-10"
                  placeholder="••••••"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-2 top-1/2 -translate-y-1/2 p-1 text-gray-400 hover:text-gray-600"
                >
                  {showPassword ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
                </button>
              </div>
              <p className="mt-1 text-xs text-gray-500">
                Le mot de passe doit contenir au moins 6 caractères
              </p>
            </div>
          )}
        </form>

        <div className="p-4 border-t flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors"
          >
            Annuler
          </button>
          <button
            onClick={handleSubmit}
            disabled={!email || !firstName || !lastName || (createMode === 'password' && (!password || password.length < 6)) || isLoading}
            className="px-4 py-2 text-sm font-medium text-white bg-[#2665EB] hover:bg-[#1b4bbd] rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isLoading ? 'Création...' : 'Créer l\'utilisateur'}
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
}