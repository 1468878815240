import React from 'react';
import { motion } from 'framer-motion';
import { Bot } from 'lucide-react';

interface PageLoaderProps {
  message?: string;
  description?: string;
}

export function PageLoader({ message = 'Chargement...', description }: PageLoaderProps) {
  return (
    <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center z-50">
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        className="bg-white/80 backdrop-blur-md rounded-2xl p-8 shadow-xl max-w-md mx-4"
      >
        <div className="flex flex-col items-center">
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="relative mb-8"
          >
            {/* Background gradient animation */}
            <motion.div
              animate={{ 
                scale: [1, 1.2, 1],
                opacity: [0.5, 0.8, 0.5] 
              }}
              transition={{ 
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
              }}
              className="absolute inset-0 bg-gradient-to-r from-[#2665EB]/20 to-[#1b4bbd]/20 rounded-xl blur-xl"
            />
            
            {/* Logo container */}
            <div className="relative h-20 w-20 rounded-xl bg-gradient-to-br from-[#2665EB] to-[#1b4bbd] shadow-lg flex items-center justify-center">
              <Bot className="h-10 w-10 text-white" />
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-center"
          >
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              {message}
            </h2>
            {description && (
              <p className="text-gray-500 max-w-sm">
                {description}
              </p>
            )}
          </motion.div>

          {/* Loading dots */}
          <div className="flex gap-2 mt-6">
            {[0, 1, 2].map((i) => (
              <motion.div
                key={i}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  duration: 0.5,
                  repeat: Infinity,
                  repeatType: "reverse",
                  delay: i * 0.2
                }}
                className="w-2 h-2 rounded-full bg-[#2665EB]"
              />
            ))}
          </div>
        </div>
      </motion.div>
    </div>
  );
}