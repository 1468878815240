import { 
  collection,
  doc,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
  serverTimestamp,
  Timestamp
} from 'firebase/firestore';
import { db } from '../../config/firebase';
import { Campaign } from '../../types/campaign';

const CAMPAIGNS_COLLECTION = 'campaigns';

export async function getAllCampaigns() {
  try {
    const q = query(
      collection(db, CAMPAIGNS_COLLECTION),
      orderBy('dateCreated', 'desc')
    );
    
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => {
      const data = doc.data();
      const dateCreated = data.dateCreated instanceof Timestamp 
        ? data.dateCreated.toDate().toISOString()
        : data.dateCreated || new Date().toISOString();
        
      return {
        id: doc.id,
        name: data.name || '',
        type: data.type || 'purchase',
        description: data.description || '',
        isActive: data.isActive ?? true,
        dateCreated,
        totalLeads: data.totalLeads || 0,
        totalProspects: data.totalProspects || 0,
        pricePerProspect: parseFloat(data.pricePerProspect) || 0,
        pricePerLead: parseFloat(data.pricePerLead) || 0
      } as Campaign;
    });
  } catch (error) {
    console.error('Error getting campaigns:', error);
    throw error;
  }
}

export async function createCampaign(campaign: Omit<Campaign, 'id' | 'dateCreated'>) {
  try {
    const campaignData = {
      name: campaign.name,
      type: campaign.type,
      description: campaign.description || '',
      isActive: campaign.isActive,
      pricePerProspect: parseFloat(campaign.pricePerProspect.toString()) || 0,
      pricePerLead: parseFloat((campaign.pricePerLead || 0).toString()),
      totalLeads: 0,
      totalProspects: 0,
      dateCreated: serverTimestamp(),
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    };

    const docRef = await addDoc(collection(db, CAMPAIGNS_COLLECTION), campaignData);
    return docRef.id;
  } catch (error) {
    console.error('Error creating campaign:', error);
    throw error;
  }
}

export async function updateCampaign(campaign: Campaign) {
  try {
    const docRef = doc(db, CAMPAIGNS_COLLECTION, campaign.id);
    const { id, dateCreated, ...campaignData } = campaign;
    
    const updateData = {
      ...campaignData,
      pricePerProspect: parseFloat(campaignData.pricePerProspect.toString()) || 0,
      pricePerLead: parseFloat((campaignData.pricePerLead || 0).toString()),
      updatedAt: serverTimestamp()
    };

    await updateDoc(docRef, updateData);
  } catch (error) {
    console.error('Error updating campaign:', error);
    throw error;
  }
}

export async function deleteCampaign(id: string) {
  try {
    const docRef = doc(db, CAMPAIGNS_COLLECTION, id);
    await deleteDoc(docRef);
  } catch (error) {
    console.error('Error deleting campaign:', error);
    throw error;
  }
}