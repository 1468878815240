import React from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Clock, Tag, DollarSign, Target, History } from 'lucide-react';
import { Prospect, ProspectHistoryEvent } from '../types/prospect';
import { STATUS_LABELS } from '../constants/status';
import { useCampaigns } from '../hooks/useCampaigns';

interface ProspectHistoryProps {
  prospect: Prospect;
}

export function ProspectHistory({ prospect }: ProspectHistoryProps) {
  const { campaigns } = useCampaigns();

  const formatDateTime = (dateString: string) => {
    return format(new Date(dateString), 'dd/MM/yyyy HH:mm', { locale: fr });
  };

  const getEventIcon = (type: ProspectHistoryEvent['type']) => {
    switch (type) {
      case 'STATUS_CHANGE':
        return Tag;
      case 'CAMPAIGN_CHANGE':
        return Target;
      case 'PRICE_CHANGE':
        return DollarSign;
      case 'CALL':
        return Clock;
      case 'SALE':
        return DollarSign;
      default:
        return History;
    }
  };

  const getEventColors = (type: ProspectHistoryEvent['type']) => {
    switch (type) {
      case 'STATUS_CHANGE':
        return { icon: 'text-blue-500', bg: 'bg-blue-50' };
      case 'CAMPAIGN_CHANGE':
        return { icon: 'text-purple-500', bg: 'bg-purple-50' };
      case 'PRICE_CHANGE':
        return { icon: 'text-emerald-500', bg: 'bg-emerald-50' };
      case 'CALL':
        return { icon: 'text-orange-500', bg: 'bg-orange-50' };
      case 'SALE':
        return { icon: 'text-emerald-500', bg: 'bg-emerald-50' };
      default:
        return { icon: 'text-gray-500', bg: 'bg-gray-50' };
    }
  };

  const history = prospect.history || [];

  return (
    <div className="mt-6">
      <h3 className="text-sm font-medium text-gray-500 mb-4">Historique</h3>
      <div className="space-y-4">
        {/* Creation event */}
        <div className="flex gap-3">
          <div className="p-2 rounded-lg bg-blue-50 flex-shrink-0 mt-1">
            <History className="h-4 w-4 text-blue-500" />
          </div>
          <div>
            <div className="flex items-center gap-2">
              <span className="text-sm font-medium text-gray-900">
                Création du prospect
              </span>
              <span className="text-xs text-gray-500">
                {formatDateTime(prospect.dateCreation)}
              </span>
            </div>
            <p className="text-sm text-gray-600 mt-0.5">
              Prix prospect initial: {prospect.prospectPrice}€
            </p>
          </div>
        </div>

        {/* History events */}
        {history.map((event, index) => {
          const Icon = getEventIcon(event.type);
          const colors = getEventColors(event.type);

          return (
            <div key={index} className="flex gap-3">
              <div className={`p-2 rounded-lg ${colors.bg} flex-shrink-0 mt-1`}>
                <Icon className={`h-4 w-4 ${colors.icon}`} />
              </div>
              <div>
                <div className="flex items-center gap-2">
                  <span className="text-sm font-medium text-gray-900">
                    {event.details}
                  </span>
                  <span className="text-xs text-gray-500">
                    {formatDateTime(event.date)}
                  </span>
                </div>
                {event.previousValue && (
                  <p className="text-sm text-gray-600 mt-0.5">
                    Valeur précédente: {event.previousValue}
                  </p>
                )}
              </div>
            </div>
          );
        })}

        {history.length === 0 && (
          <p className="text-sm text-gray-500">
            Aucun historique disponible
          </p>
        )}
      </div>
    </div>
  );
}