import Papa from 'papaparse';
import { Prospect, ProspectStatus } from '../types/prospect';
import { STATUS_LABELS } from '../constants/status';

export class CSVError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'CSVError';
  }
}

export async function parseCSV(file: File) {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: 'greedy',
      transformHeader: (header) => header.trim(),
      complete: (results) => {
        if (results.data && Array.isArray(results.data)) {
          resolve({
            data: results.data,
            headers: results.meta.fields || []
          });
        } else {
          reject(new CSVError('Format de fichier CSV invalide'));
        }
      },
      error: (error) => {
        reject(new CSVError(`Erreur lors de la lecture du fichier CSV: ${error}`));
      }
    });
  });
}

export function applyColumnMapping(data: any[], mapping: any[]) {
  try {
    if (!Array.isArray(data)) {
      throw new CSVError('Les données à mapper ne sont pas au bon format');
    }

    if (!Array.isArray(mapping) || mapping.length === 0) {
      throw new CSVError('Le mapping des colonnes est invalide');
    }

    return data.map(row => {
      const mappedRow: Partial<Prospect> = {
        id: crypto.randomUUID(),
        status: 'NOUVEAU',
        dateCreation: new Date().toISOString(),
        proprietaire: false,
        credit: false,
        montantElectricite: 0,
        revenus: 0,
        prospectPrice: 0,
        leadPrice: 0
      };

      mapping.forEach(({ csvHeader, crmField }) => {
        if (!csvHeader || !crmField) return;

        let value = row[csvHeader];
        if (value === undefined || value === null || value === '') return;

        value = value.toString().trim();

        switch (crmField) {
          case 'proprietaire':
          case 'credit':
            mappedRow[crmField] = ['true', 'oui', '1', 'yes', 'vrai'].includes(value.toLowerCase());
            break;

          case 'montantElectricite':
          case 'revenus':
          case 'leadPrice':
          case 'prospectPrice':
            const numStr = value.replace(/[^0-9.,]/g, '').replace(',', '.');
            const num = parseFloat(numStr);
            mappedRow[crmField] = isNaN(num) ? 0 : num;
            break;

          case 'telephone':
            mappedRow[crmField] = value.replace(/[^0-9+]/g, '');
            break;

          case 'email':
            mappedRow[crmField] = value.toLowerCase();
            break;

          case 'codePostal':
            mappedRow.codePostal = value.replace(/[^0-9]/g, '');
            if (mappedRow.codePostal.length >= 2) {
              mappedRow.departement = mappedRow.codePostal.substring(0, 2);
            }
            break;

          default:
            mappedRow[crmField] = value;
        }
      });

      return mappedRow;
    });
  } catch (error) {
    if (error instanceof CSVError) {
      throw error;
    }
    throw new CSVError('Erreur lors du mapping des données');
  }
}