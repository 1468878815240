import React from 'react';
import { motion } from 'framer-motion';
import { FileUp, CheckCircle2 } from 'lucide-react';

interface ImportLoaderProps {
  totalCount: number;
  currentCount: number;
  isComplete?: boolean;
}

export function ImportLoader({ totalCount, currentCount, isComplete }: ImportLoaderProps) {
  const progress = (currentCount / totalCount) * 100;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        className="bg-white rounded-xl p-8 max-w-md w-full mx-4 shadow-xl"
      >
        <div className="flex flex-col items-center">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: "spring", stiffness: 260, damping: 20 }}
            className="w-20 h-20 rounded-full bg-[#2665EB]/10 flex items-center justify-center mb-6"
          >
            {isComplete ? (
              <CheckCircle2 className="h-10 w-10 text-[#2665EB]" />
            ) : (
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
              >
                <FileUp className="h-10 w-10 text-[#2665EB]" />
              </motion.div>
            )}
          </motion.div>

          <h3 className="text-xl font-semibold text-gray-900 mb-2">
            {isComplete ? 'Import terminé' : 'Import en cours...'}
          </h3>
          
          <p className="text-gray-500 mb-6 text-center">
            {isComplete 
              ? `${totalCount} prospects importés avec succès`
              : `${currentCount} sur ${totalCount} prospects`}
          </p>

          <div className="w-full bg-gray-100 rounded-full h-2 mb-4">
            <motion.div
              initial={{ width: 0 }}
              animate={{ width: `${progress}%` }}
              transition={{ duration: 0.5 }}
              className="h-2 rounded-full bg-gradient-to-r from-[#2665EB] to-[#1b4bbd]"
            />
          </div>

          <p className="text-sm text-gray-500">
            {isComplete ? 'Vous pouvez fermer cette fenêtre' : 'Veuillez patienter...'}
          </p>
        </div>
      </motion.div>
    </div>
  );
}