import React, { useState } from 'react';
import { X, DollarSign, Tag } from 'lucide-react';
import { Prospect, ProspectStatus } from '../types/prospect';
import { STATUS_LABELS } from '../constants/status';
import { motion } from 'framer-motion';
import { useCampaigns } from '../hooks/useCampaigns';
import Select from 'react-select';

interface ImportPreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  previewData: Partial<Prospect>[] | null;
  onConfirm: (campaignId: string, status: ProspectStatus, source: string) => void;
}

export function ImportPreviewModal({
  isOpen,
  onClose,
  previewData,
  onConfirm
}: ImportPreviewModalProps) {
  const { campaigns } = useCampaigns();
  const [selectedCampaign, setSelectedCampaign] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<ProspectStatus>('NOUVEAU');
  const [source, setSource] = useState<string>('');

  if (!isOpen || !previewData) return null;

  const campaignOptions = campaigns
    .filter(campaign => campaign.isActive && campaign.type === 'purchase')
    .map(campaign => ({
      value: campaign.id,
      label: `${campaign.name} (${campaign.pricePerProspect}€/prospect)`
    }));

  const handleSubmit = () => {
    onConfirm(selectedCampaign, selectedStatus, source);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-white rounded-xl w-full max-w-lg mx-4 shadow-xl"
      >
        <div className="p-4 border-b flex items-center justify-between">
          <h2 className="text-lg font-semibold text-gray-900">
            Aperçu de l'import ({previewData.length} prospects)
          </h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
          >
            <X className="h-5 w-5 text-gray-500" />
          </button>
        </div>

        <div className="p-4 overflow-y-auto max-h-[calc(90vh-12rem)]">
          <div className="space-y-4 mb-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Campagne d'achat
              </label>
              <Select
                options={campaignOptions}
                value={campaignOptions.find(option => option.value === selectedCampaign)}
                onChange={(option) => setSelectedCampaign(option?.value || '')}
                placeholder="Sélectionner une campagne..."
                className="text-sm"
                isSearchable
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Statut initial
              </label>
              <select
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value as ProspectStatus)}
                className="w-full border rounded-lg px-3 py-2 focus:ring-2 focus:ring-[#2665EB] focus:border-[#2665EB] outline-none"
              >
                {Object.entries(STATUS_LABELS).map(([value, label]) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Source
              </label>
              <input
                type="text"
                value={source}
                onChange={(e) => setSource(e.target.value)}
                placeholder="Ex: Site web, Partenaire, etc."
                className="w-full border rounded-lg px-3 py-2 focus:ring-2 focus:ring-[#2665EB] focus:border-[#2665EB] outline-none"
                required
              />
            </div>
          </div>

          <div>
            <h3 className="text-lg font-semibold text-green-600 mb-2">
              Aperçu des données
            </h3>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {['Nom', 'Prénom', 'Email', 'Téléphone', 'Ville'].map((header) => (
                      <th
                        key={header}
                        className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {previewData.slice(0, 5).map((prospect, index) => (
                    <tr key={index}>
                      <td className="px-4 py-2">{prospect.nom}</td>
                      <td className="px-4 py-2">{prospect.prenom}</td>
                      <td className="px-4 py-2">{prospect.email}</td>
                      <td className="px-4 py-2">{prospect.telephone}</td>
                      <td className="px-4 py-2">{prospect.ville}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {previewData.length > 5 && (
                <p className="text-sm text-gray-500 mt-2 px-4">
                  Et {previewData.length - 5} autres prospects...
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="p-4 border-t flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors"
          >
            Annuler
          </button>
          <button
            onClick={handleSubmit}
            disabled={!selectedCampaign || !source}
            className="px-4 py-2 text-sm font-medium text-white bg-[#2665EB] hover:bg-[#1b4bbd] rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Importer {previewData.length} prospect{previewData.length > 1 ? 's' : ''}
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
}