import React from 'react';
import { ProspectList } from '../components/ProspectList';
import { useProspects } from '../hooks/useProspects';
import { PageLoader } from '../components/common/PageLoader';
import { Card } from '../components/common/Card';
import { exportToCSV } from '../utils/csvExport';
import toast from 'react-hot-toast';
import { Prospect } from '../types/prospect';

export function ProspectsPage() {
  const { prospects, isLoading, importProgress, importProspects, deleteProspects, updateProspect } = useProspects();

  if (isLoading) {
    return (
      <PageLoader 
        message="Chargement des prospects" 
        description="Connexion à la base de données et récupération des informations..."
      />
    );
  }

  const handleExportCSV = (selectedFields: string[], selectedProspects: Prospect[]) => {
    try {
      if (selectedProspects.length === 0) {
        toast.error('Veuillez sélectionner au moins un prospect à exporter');
        return;
      }
      exportToCSV(selectedProspects, selectedFields);
      toast.success(`${selectedProspects.length} prospect(s) exporté(s) avec succès`);
    } catch (error) {
      console.error('Erreur lors de l\'export:', error);
      toast.error(error instanceof Error ? error.message : 'Erreur lors de l\'export CSV');
    }
  };

  const handleImportCSV = async (data: any[], campaignId: string) => {
    try {
      await importProspects(data, campaignId);
      toast.success('Import réussi');
    } catch (error) {
      console.error('Erreur lors de l\'import:', error);
      toast.error('Erreur lors de l\'import CSV');
    }
  };

  return (
    <div className="min-h-screen" style={{ backgroundColor: '#F1F1F1' }}>
      <div className="max-w-[1920px] mx-auto px-4 py-4">
        <div className="mb-4">
          <div className="flex items-center justify-between mb-1">
            <h1 className="text-xl font-semibold text-gray-900">Prospects</h1>
            <div className="text-xs text-gray-500">
              {prospects.length} prospect{prospects.length > 1 ? 's' : ''} au total
            </div>
          </div>
          <p className="text-sm text-gray-500">
            Gérez vos prospects et suivez leur progression dans le tunnel de conversion
          </p>
        </div>

        <Card className="overflow-hidden border-0 shadow-lg">
          <ProspectList
            prospects={prospects}
            onCallSelected={() => {}}
            onExportCSV={handleExportCSV}
            onImportCSV={handleImportCSV}
            onDeleteProspects={deleteProspects}
            onUpdateProspect={updateProspect}
            importProgress={importProgress}
          />
        </Card>
      </div>
    </div>
  );
}