import React, { useMemo } from 'react';
import { Card } from '../common/Card';
import { Prospect } from '../../types/prospect';
import { format, startOfMonth, endOfMonth, eachMonthOfInterval, subMonths } from 'date-fns';
import { fr } from 'date-fns/locale';

interface PerformanceChartProps {
  prospects: Prospect[];
}

export function PerformanceChart({ prospects }: PerformanceChartProps) {
  const chartData = useMemo(() => {
    // Obtenir les 12 derniers mois
    const endDate = new Date();
    const startDate = subMonths(endDate, 11);
    const months = eachMonthOfInterval({ start: startDate, end: endDate });

    return months.map(month => {
      const monthStart = startOfMonth(month);
      const monthEnd = endOfMonth(month);

      // Filtrer les prospects pour ce mois
      const monthProspects = prospects.filter(prospect => {
        const date = new Date(prospect.dateCreation);
        return date >= monthStart && date <= monthEnd;
      });

      // Compter les différents types
      const totalProspects = monthProspects.length;
      const leads = monthProspects.filter(p => p.status === 'LEADS').length;
      const vendus = monthProspects.filter(p => p.status === 'VENDU').length;

      return {
        month: format(month, 'MMM', { locale: fr }),
        totalProspects,
        leads,
        vendus
      };
    });
  }, [prospects]);

  // Trouver la valeur maximale pour l'échelle
  const maxValue = Math.max(
    ...chartData.map(data => Math.max(data.totalProspects, data.leads, data.vendus))
  );

  // Calculer les graduations
  const yAxisScale = Array.from({ length: 5 }, (_, i) => 
    Math.round((maxValue / 4) * i)
  );

  return (
    <Card className="p-4">
      <h3 className="text-base font-semibold mb-6">Évolution de l'activité</h3>
      
      <div className="relative h-80">
        {/* Axe Y et graduations */}
        <div className="absolute left-0 top-0 bottom-0 w-12 flex flex-col justify-between text-xs text-gray-500">
          {yAxisScale.reverse().map((value, index) => (
            <div key={index} className="flex items-center justify-end w-full pr-2">
              {value}
            </div>
          ))}
        </div>

        {/* Grille et graphique */}
        <div className="absolute left-12 right-0 top-0 bottom-0">
          {/* Lignes horizontales de la grille */}
          {yAxisScale.map((_, index) => (
            <div
              key={index}
              className="absolute w-full border-t border-gray-100"
              style={{ top: `${(index * 100) / 4}%` }}
            />
          ))}

          {/* Barres du graphique */}
          <div className="relative h-full flex items-end">
            {chartData.map((data, index) => {
              const prospectHeight = (data.totalProspects / maxValue) * 100;
              const leadsHeight = (data.leads / maxValue) * 100;
              const vendusHeight = (data.vendus / maxValue) * 100;

              return (
                <div
                  key={index}
                  className="flex-1 flex items-end justify-center gap-1"
                >
                  <div
                    className="w-3 bg-blue-200 rounded-t transition-all duration-300"
                    style={{ height: `${prospectHeight}%` }}
                    title={`${data.totalProspects} prospects`}
                  />
                  <div
                    className="w-3 bg-green-200 rounded-t transition-all duration-300"
                    style={{ height: `${leadsHeight}%` }}
                    title={`${data.leads} leads`}
                  />
                  <div
                    className="w-3 bg-purple-200 rounded-t transition-all duration-300"
                    style={{ height: `${vendusHeight}%` }}
                    title={`${data.vendus} vendus`}
                  />
                </div>
              );
            })}
          </div>

          {/* Axe X (mois) */}
          <div className="absolute bottom-0 w-full flex justify-between text-xs text-gray-500 -mb-6">
            {chartData.map((data, index) => (
              <div key={index} className="text-center" style={{ width: `${100 / 12}%` }}>
                {data.month}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Légende */}
      <div className="flex items-center justify-center gap-6 mt-12">
        <div className="flex items-center gap-2">
          <div className="w-3 h-3 bg-blue-200 rounded" />
          <span className="text-sm text-gray-600">Prospects</span>
        </div>
        <div className="flex items-center gap-2">
          <div className="w-3 h-3 bg-green-200 rounded" />
          <span className="text-sm text-gray-600">Leads</span>
        </div>
        <div className="flex items-center gap-2">
          <div className="w-3 h-3 bg-purple-200 rounded" />
          <span className="text-sm text-gray-600">Vendus</span>
        </div>
      </div>
    </Card>
  );
}