import React from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Prospect } from '../types/prospect';
import { STATUS_COLORS, STATUS_LABELS } from '../constants/status';
import { clsx } from 'clsx';
import { useCampaigns } from '../hooks/useCampaigns';

interface ProspectTableRowProps {
  prospect: Prospect;
  isSelected: boolean;
  onSelect: (id: string, checked: boolean) => void;
  onClick: (prospect: Prospect) => void;
}

export function ProspectTableRow({
  prospect,
  isSelected,
  onSelect,
  onClick
}: ProspectTableRowProps) {
  const { campaigns } = useCampaigns();

  const formatDate = (dateString: string | undefined) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    return format(date, 'dd/MM/yy', { locale: fr });
  };

  const formatDateTime = (dateString: string | undefined) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    return format(date, 'dd/MM HH:mm', { locale: fr });
  };

  const handleRowClick = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement;
    if (!target.closest('input[type="checkbox"]')) {
      onClick(prospect);
    }
  };

  const campaign = campaigns.find(c => c.id === prospect.campagne);

  return (
    <tr 
      onClick={handleRowClick}
      className={clsx(
        'hover:bg-gray-50 transition-colors cursor-pointer text-[14px]',
        isSelected && 'bg-[#2665EB]/5'
      )}
    >
      <td className="px-2 py-1.5" onClick={e => e.stopPropagation()}>
        <input
          type="checkbox"
          checked={isSelected}
          onChange={(e) => onSelect(prospect.id, e.target.checked)}
          className="rounded border-gray-300 text-[#2665EB] focus:ring-[#2665EB] cursor-pointer"
        />
      </td>
      <td className="px-2 py-1.5 font-medium max-w-[120px] truncate">{prospect.nom || '-'}</td>
      <td className="px-2 py-1.5 max-w-[120px] truncate">{prospect.prenom || '-'}</td>
      <td className="px-2 py-1.5">{prospect.telephone || '-'}</td>
      <td className="px-2 py-1.5 max-w-[180px] truncate">{prospect.email || '-'}</td>
      <td className="px-2 py-1.5 max-w-[120px] truncate">{prospect.ville || '-'}</td>
      <td className="px-2 py-1.5 max-w-[100px] truncate">{prospect.source || '-'}</td>
      <td className="px-2 py-1.5 max-w-[120px] truncate">{campaign?.name || '-'}</td>
      <td className="px-2 py-1.5 text-right tabular-nums">
        {typeof prospect.prospectPrice === 'number' ? (
          <span className="text-gray-900">{prospect.prospectPrice}€</span>
        ) : '-'}
      </td>
      <td className="px-2 py-1.5">
        <span className={clsx(
          'px-1.5 py-0.5 text-[12px] font-medium rounded-full inline-flex items-center',
          STATUS_COLORS[prospect.status]
        )}>
          {STATUS_LABELS[prospect.status]}
        </span>
      </td>
      <td className="px-2 py-1.5 text-center">{prospect.proprietaire ? 'Oui' : 'Non'}</td>
      <td className="px-2 py-1.5 text-center">{prospect.codePostal || '-'}</td>
      <td className="px-2 py-1.5 text-center">{prospect.departement || '-'}</td>
      <td className="px-2 py-1.5 max-w-[100px] truncate">{prospect.modeChauffage || '-'}</td>
      <td className="px-2 py-1.5 text-right tabular-nums">
        {prospect.montantElectricite ? `${prospect.montantElectricite}€` : '-'}
      </td>
      <td className="px-2 py-1.5 text-right tabular-nums">
        {prospect.revenus ? `${prospect.revenus}€` : '-'}
      </td>
      <td className="px-2 py-1.5 text-center">{prospect.credit || '-'}</td>
      <td className="px-2 py-1.5 max-w-[100px] truncate">{prospect.solution || '-'}</td>
      <td className="px-2 py-1.5 text-right tabular-nums">
        {typeof prospect.leadPrice === 'number' ? (
          <span className="text-emerald-600">{prospect.leadPrice}€</span>
        ) : '-'}
      </td>
      <td className="px-2 py-1.5 text-gray-500">
        {formatDate(prospect.dateCreation)}
      </td>
      <td className="px-2 py-1.5 text-gray-500">
        {formatDateTime(prospect.dernierAppel)}
      </td>
    </tr>
  );
}