import React, { useState } from 'react';
import { X, DollarSign } from 'lucide-react';
import { Prospect } from '../types/prospect';
import { motion } from 'framer-motion';
import { useCampaigns } from '../hooks/useCampaigns';
import Select from 'react-select';

interface BulkPriceUpdateModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedProspects: Prospect[];
  onUpdate: (prospects: Prospect[]) => void;
}

export function BulkPriceUpdateModal({
  isOpen,
  onClose,
  selectedProspects,
  onUpdate
}: BulkPriceUpdateModalProps) {
  const { campaigns } = useCampaigns();
  const [purchaseCampaignId, setPurchaseCampaignId] = useState<string>('');
  const [saleCampaignId, setSaleCampaignId] = useState<string>('');

  const purchaseCampaigns = campaigns.filter(c => c.type === 'purchase');
  const salesCampaigns = campaigns.filter(c => c.type === 'sales');

  if (!isOpen) return null;

  const handleSubmit = () => {
    const updatedProspects = selectedProspects.map(prospect => {
      const updates: Partial<Prospect> = { ...prospect };

      // Update purchase campaign and prospect price if selected
      if (purchaseCampaignId) {
        const purchaseCampaign = campaigns.find(c => c.id === purchaseCampaignId);
        if (purchaseCampaign) {
          updates.campagne = purchaseCampaignId;
          updates.prospectPrice = purchaseCampaign.pricePerProspect;
        }
      }

      // Update lead price if sales campaign is selected
      if (saleCampaignId) {
        const salesCampaign = campaigns.find(c => c.id === saleCampaignId);
        if (salesCampaign && salesCampaign.pricePerLead) {
          updates.leadPrice = salesCampaign.pricePerLead;
          
          // If prospect is sold, ensure dateSold is set
          if (updates.status === 'VENDU' && !updates.dateSold) {
            updates.dateSold = new Date().toISOString();
          }
        }
      }

      // Ensure numeric values are properly set
      return {
        ...prospect,
        ...updates,
        prospectPrice: Number(updates.prospectPrice) || 0,
        leadPrice: Number(updates.leadPrice) || 0
      };
    });

    // Log the updates for debugging
    console.log('Updating prospects with:', updatedProspects);

    onUpdate(updatedProspects);
    onClose();
  };

  const campaignOptions = {
    purchase: purchaseCampaigns.map(campaign => ({
      value: campaign.id,
      label: `${campaign.name} (${campaign.pricePerProspect}€/prospect)`
    })),
    sales: salesCampaigns.map(campaign => ({
      value: campaign.id,
      label: `${campaign.name} (${campaign.pricePerLead}€/lead)`
    }))
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-white rounded-xl w-full max-w-lg mx-4 shadow-xl"
      >
        <div className="p-4 border-b flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div className="p-2 rounded-lg bg-[#2665EB]/10">
              <DollarSign className="h-5 w-5 text-[#2665EB]" />
            </div>
            <div>
              <h2 className="text-lg font-semibold text-gray-900">Modifier les prix</h2>
              <p className="text-sm text-gray-500">
                {selectedProspects.length} prospect{selectedProspects.length > 1 ? 's' : ''} sélectionné{selectedProspects.length > 1 ? 's' : ''}
              </p>
            </div>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
          >
            <X className="h-5 w-5 text-gray-500" />
          </button>
        </div>

        <div className="p-6 space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Campagne d'achat
            </label>
            <Select
              value={campaignOptions.purchase.find(option => option.value === purchaseCampaignId)}
              onChange={(option) => setPurchaseCampaignId(option?.value || '')}
              options={campaignOptions.purchase}
              isClearable
              placeholder="Sélectionner une campagne..."
              className="text-sm"
            />
            {purchaseCampaignId && (
              <p className="mt-1 text-sm text-gray-500">
                Le prix du prospect sera automatiquement défini selon la campagne sélectionnée
              </p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Campagne de vente
            </label>
            <Select
              value={campaignOptions.sales.find(option => option.value === saleCampaignId)}
              onChange={(option) => setSaleCampaignId(option?.value || '')}
              options={campaignOptions.sales}
              isClearable
              placeholder="Sélectionner une campagne..."
              className="text-sm"
            />
            {saleCampaignId && (
              <p className="mt-1 text-sm text-gray-500">
                Le prix du lead sera automatiquement défini selon la campagne sélectionnée
              </p>
            )}
          </div>
        </div>

        <div className="p-4 border-t flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors"
          >
            Annuler
          </button>
          <button
            onClick={handleSubmit}
            disabled={!purchaseCampaignId && !saleCampaignId}
            className="px-4 py-2 text-sm font-medium text-white bg-[#2665EB] hover:bg-[#1b4bbd] rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Mettre à jour les prix
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
}