import React, { useState, useEffect } from 'react';
import { Search, X, Calendar } from 'lucide-react';
import { ProspectStatus } from '../types/prospect';
import { STATUS_LABELS, STATUS_COLORS } from '../constants/status';
import { DepartmentSearch } from './DepartmentSearch';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
import { fr } from 'date-fns/locale';
import "react-datepicker/dist/react-datepicker.css";
import { useCampaigns } from '../hooks/useCampaigns';

registerLocale('fr', fr);

interface FilterValues {
  search: string;
  status: ProspectStatus[];
  proprietaire: string;
  credit: string;
  modeChauffage: string[];
  campagne: string[];
  departements: string[];
  codePostal: string[];
  montantMin: string;
  montantMax: string;
  revenusMin: string;
  revenusMax: string;
  dateImportDebut: Date | null;
  dateImportFin: Date | null;
  dateAppelDebut: Date | null;
  dateAppelFin: Date | null;
}

interface ProspectFiltersProps {
  filters: FilterValues;
  onFilterChange: (filters: FilterValues) => void;
  showAdvanced: boolean;
  onToggleAdvanced: () => void;
  uniqueValues: {
    modeChauffage: string[];
    campagne: string[];
    departement: string[];
    codePostal: string[];
  };
}

export function ProspectFilters({
  filters,
  onFilterChange,
  showAdvanced,
  onToggleAdvanced,
  uniqueValues
}: ProspectFiltersProps) {
  const [searchValue, setSearchValue] = useState(filters.search);
  const [postalCodeSearch, setPostalCodeSearch] = useState('');
  const { campaigns } = useCampaigns();

  useEffect(() => {
    const timer = setTimeout(() => {
      onFilterChange({
        ...filters,
        search: searchValue
      });
    }, 300);

    return () => clearTimeout(timer);
  }, [searchValue]);

  const handleChange = (field: keyof FilterValues, value: any) => {
    onFilterChange({
      ...filters,
      [field]: value
    });
  };

  const handlePostalCodeSearch = (value: string) => {
    setPostalCodeSearch(value);
    
    if (value.length >= 2) {
      const matchingCodes = uniqueValues.codePostal.filter(code => 
        code.startsWith(value)
      );
      handleChange('codePostal', matchingCodes);
    } else {
      handleChange('codePostal', []);
    }
  };

  const handleRemoveStatus = (statusToRemove: ProspectStatus) => {
    handleChange('status', filters.status.filter(status => status !== statusToRemove));
  };

  const handleClearFilters = () => {
    onFilterChange({
      search: '',
      status: [],
      proprietaire: '',
      credit: '',
      modeChauffage: [],
      campagne: [],
      departements: [],
      codePostal: [],
      montantMin: '',
      montantMax: '',
      revenusMin: '',
      revenusMax: '',
      dateImportDebut: null,
      dateImportFin: null,
      dateAppelDebut: null,
      dateAppelFin: null
    });
    setSearchValue('');
    setPostalCodeSearch('');
  };

  const hasActiveFilters = Object.values(filters).some(value => 
    Array.isArray(value) ? value.length > 0 : Boolean(value)
  );

  // Prepare campaign options with prices
  const campaignOptions = campaigns
    .filter(campaign => uniqueValues.campagne.includes(campaign.id))
    .map(campaign => ({
      value: campaign.id,
      label: `${campaign.name} (${campaign.type === 'purchase' ? campaign.pricePerProspect : campaign.pricePerLead}€/${campaign.type === 'purchase' ? 'prospect' : 'lead'})`
    }));

  return (
    <div className="space-y-6 p-4 bg-white border-b">
      {/* Basic filters */}
      <div className="flex flex-wrap items-center gap-3">
        <div className="relative flex-1 min-w-[300px]">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
          <input
            type="text"
            placeholder="Rechercher par nom, email, téléphone, ville..."
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            className="w-full pl-9 pr-3 py-2 text-sm border rounded-lg focus:ring-2 focus:ring-[#2665EB] focus:border-[#2665EB] outline-none bg-white"
          />
        </div>

        <div className="w-64">
          <Select
            isMulti
            value={filters.status.map(status => ({
              value: status,
              label: STATUS_LABELS[status]
            }))}
            onChange={(selected) => handleChange('status', selected?.map(option => option.value) || [])}
            options={Object.entries(STATUS_LABELS).map(([value, label]) => ({
              value,
              label
            }))}
            placeholder="Filtrer par statut"
            className="text-sm"
            classNames={{
              control: (state) => `!bg-white !border-gray-200 !shadow-none ${
                state.isFocused ? '!border-[#2665EB] !ring-2 !ring-[#2665EB]/50' : ''
              }`,
              option: (state) => `!text-sm ${
                state.isSelected ? '!bg-[#2665EB]' : state.isFocused ? '!bg-[#2665EB]/10' : ''
              }`
            }}
            components={{
              MultiValue: ({ data, removeProps }) => (
                <div className={`inline-flex items-center gap-1 px-2 py-0.5 m-0.5 rounded-full text-xs font-medium ${
                  STATUS_COLORS[data.value as ProspectStatus]
                }`}>
                  {data.label}
                  <button
                    {...removeProps}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveStatus(data.value as ProspectStatus);
                    }}
                    className="ml-1 hover:text-gray-900"
                  >
                    <X className="h-3 w-3" />
                  </button>
                </div>
              )
            }}
          />
        </div>

        {hasActiveFilters && (
          <button
            onClick={handleClearFilters}
            className="inline-flex items-center gap-2 px-3 py-1.5 text-sm font-medium text-gray-700 hover:text-gray-900"
          >
            <X className="h-4 w-4" />
            Réinitialiser
          </button>
        )}
      </div>

      {/* Advanced filters */}
      {showAdvanced && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 pt-6 border-t">
          <div className="space-y-4">
            <h3 className="text-sm font-medium text-gray-900">Localisation</h3>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1.5">
                Code postal
              </label>
              <div className="relative">
                <input
                  type="text"
                  value={postalCodeSearch}
                  onChange={(e) => handlePostalCodeSearch(e.target.value)}
                  placeholder="Entrez au moins 2 chiffres..."
                  className="w-full px-3 py-2 text-sm border rounded-lg focus:ring-2 focus:ring-[#2665EB] focus:border-[#2665EB] outline-none bg-white"
                  maxLength={5}
                />
                {filters.codePostal.length > 0 && (
                  <div className="mt-2">
                    <div className="text-xs text-gray-500 mb-1.5">
                      {filters.codePostal.length} code{filters.codePostal.length > 1 ? 's' : ''} postal{filters.codePostal.length > 1 ? 'aux' : ''} sélectionné{filters.codePostal.length > 1 ? 's' : ''}
                    </div>
                    <div className="flex flex-wrap gap-1.5">
                      {filters.codePostal.slice(0, 3).map(code => (
                        <span key={code} className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-[#2665EB]/10 text-[#2665EB]">
                          {code}
                        </span>
                      ))}
                      {filters.codePostal.length > 3 && (
                        <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-gray-100 text-gray-700">
                          +{filters.codePostal.length - 3}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <DepartmentSearch
              value={filters.departements}
              onChange={(value) => handleChange('departements', value)}
              departments={uniqueValues.departement}
            />
          </div>

          <div className="space-y-4">
            <h3 className="text-sm font-medium text-gray-900">Situation</h3>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1.5">
                Propriétaire
              </label>
              <select
                value={filters.proprietaire}
                onChange={(e) => handleChange('proprietaire', e.target.value)}
                className="w-full px-3 py-2 text-sm border rounded-lg focus:ring-2 focus:ring-[#2665EB] focus:border-[#2665EB] outline-none bg-white"
              >
                <option value="">Tous</option>
                <option value="true">Oui</option>
                <option value="false">Non</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1.5">
                Crédit en cours
              </label>
              <select
                value={filters.credit}
                onChange={(e) => handleChange('credit', e.target.value)}
                className="w-full px-3 py-2 text-sm border rounded-lg focus:ring-2 focus:ring-[#2665EB] focus:border-[#2665EB] outline-none bg-white"
              >
                <option value="">Tous</option>
                <option value="true">Oui</option>
                <option value="false">Non</option>
              </select>
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-sm font-medium text-gray-900">Caractéristiques</h3>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1.5">
                Mode de chauffage
              </label>
              <Select
                isMulti
                value={filters.modeChauffage.map(value => ({ value, label: value }))}
                onChange={(selected) => handleChange('modeChauffage', selected?.map(option => option.value) || [])}
                options={uniqueValues.modeChauffage.map(value => ({ value, label: value }))}
                placeholder="Sélectionner..."
                className="text-sm"
                classNames={{
                  control: (state) => `!bg-white !border-gray-200 !shadow-none ${
                    state.isFocused ? '!border-[#2665EB] !ring-2 !ring-[#2665EB]/50' : ''
                  }`,
                  option: (state) => `!text-sm ${
                    state.isSelected ? '!bg-[#2665EB]' : state.isFocused ? '!bg-[#2665EB]/10' : ''
                  }`
                }}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1.5">
                Campagne
              </label>
              <Select
                isMulti
                value={filters.campagne.map(value => {
                  const campaign = campaigns.find(c => c.id === value);
                  return {
                    value,
                    label: campaign ? `${campaign.name} (${campaign.type === 'purchase' ? campaign.pricePerProspect : campaign.pricePerLead}€/${campaign.type === 'purchase' ? 'prospect' : 'lead'})` : value
                  };
                })}
                onChange={(selected) => handleChange('campagne', selected?.map(option => option.value) || [])}
                options={campaignOptions}
                placeholder="Sélectionner..."
                className="text-sm"
                classNames={{
                  control: (state) => `!bg-white !border-gray-200 !shadow-none ${
                    state.isFocused ? '!border-[#2665EB] !ring-2 !ring-[#2665EB]/50' : ''
                  }`,
                  option: (state) => `!text-sm ${
                    state.isSelected ? '!bg-[#2665EB]' : state.isFocused ? '!bg-[#2665EB]/10' : ''
                  }`
                }}
              />
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-sm font-medium text-gray-900">Montants</h3>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1.5">
                Montant électricité (€)
              </label>
              <div className="flex gap-3">
                <div className="flex-1">
                  <input
                    type="number"
                    placeholder="Min"
                    value={filters.montantMin}
                    onChange={(e) => handleChange('montantMin', e.target.value)}
                    className="w-full px-3 py-2 text-sm border rounded-lg focus:ring-2 focus:ring-[#2665EB] focus:border-[#2665EB] outline-none bg-white"
                  />
                </div>
                <div className="flex-1">
                  <input
                    type="number"
                    placeholder="Max"
                    value={filters.montantMax}
                    onChange={(e) => handleChange('montantMax', e.target.value)}
                    className="w-full px-3 py-2 text-sm border rounded-lg focus:ring-2 focus:ring-[#2665EB] focus:border-[#2665EB] outline-none bg-white"
                  />
                </div>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1.5">
                Revenus (€)
              </label>
              <div className="flex gap-3">
                <div className="flex-1">
                  <input
                    type="number"
                    placeholder="Min"
                    value={filters.revenusMin}
                    onChange={(e) => handleChange('revenusMin', e.target.value)}
                    className="w-full px-3 py-2 text-sm border rounded-lg focus:ring-2 focus:ring-[#2665EB] focus:border-[#2665EB] outline-none bg-white"
                  />
                </div>
                <div className="flex-1">
                  <input
                    type="number"
                    placeholder="Max"
                    value={filters.revenusMax}
                    onChange={(e) => handleChange('revenusMax', e.target.value)}
                    className="w-full px-3 py-2 text-sm border rounded-lg focus:ring-2 focus:ring-[#2665EB] focus:border-[#2665EB] outline-none bg-white"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-sm font-medium text-gray-900">Dates</h3>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1.5">
                Date d'import
              </label>
              <div className="flex gap-3">
                <div className="flex-1 relative">
                  <DatePicker
                    selected={filters.dateImportDebut}
                    onChange={(date) => handleChange('dateImportDebut', date)}
                    selectsStart
                    startDate={filters.dateImportDebut}
                    endDate={filters.dateImportFin}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Date début"
                    locale="fr"
                    className="w-full px-3 py-2 text-sm border rounded-lg focus:ring-2 focus:ring-[#2665EB] focus:border-[#2665EB] outline-none bg-white pl-9"
                  />
                  <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                </div>
                <div className="flex-1 relative">
                  <DatePicker
                    selected={filters.dateImportFin}
                    onChange={(date) => handleChange('dateImportFin', date)}
                    selectsEnd
                    startDate={filters.dateImportDebut}
                    endDate={filters.dateImportFin}
                    minDate={filters.dateImportDebut}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Date fin"
                    locale="fr"
                    className="w-full px-3 py-2 text-sm border rounded-lg focus:ring-2 focus:ring-[#2665EB] focus:border-[#2665EB] outline-none bg-white pl-9"
                  />
                  <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                </div>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1.5">
                Date d'appel
              </label>
              <div className="flex gap-3">
                <div className="flex-1 relative">
                  <DatePicker
                    selected={filters.dateAppelDebut}
                    onChange={(date) => handleChange('dateAppelDebut', date)}
                    selectsStart
                    startDate={filters.dateAppelDebut}
                    endDate={filters.dateAppelFin}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Date début"
                    locale="fr"
                    className="w-full px-3 py-2 text-sm border rounded-lg focus:ring-2 focus:ring-[#2665EB] focus:border-[#2665EB] outline-none bg-white pl-9"
                  />
                  <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                </div>
                <div className="flex-1 relative">
                  <DatePicker
                    selected={filters.dateAppelFin}
                    onChange={(date) => handleChange('dateAppelFin', date)}
                    selectsEnd
                    startDate={filters.dateAppelDebut}
                    endDate={filters.dateAppelFin}
                    minDate={filters.dateAppelDebut}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Date fin"
                    locale="fr"
                    className="w-full px-3 py-2 text-sm border rounded-lg focus:ring-2 focus:ring-[#2665EB] focus:border-[#2665EB] outline-none bg-white pl-9"
                  />
                  <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}