import { 
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  query,
  where,
  orderBy,
  serverTimestamp,
  Timestamp,
  collectionGroup
} from 'firebase/firestore';
import { 
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  updateProfile
} from 'firebase/auth';
import { auth, db } from '../../config/firebase';
import { User, UserRole } from '../../types/user';

const USERS_COLLECTION = 'users';

export async function getAllUsers(): Promise<User[]> {
  try {
    // Get all users from the users collection
    const usersSnapshot = await getDocs(collection(db, USERS_COLLECTION));
    
    // Get all users from Firebase Auth
    const authUsers = await getDocs(collection(db, USERS_COLLECTION));
    
    const users: User[] = [];
    
    usersSnapshot.forEach(doc => {
      const data = doc.data();
      users.push({
        id: doc.id,
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        email: data.email || '',
        role: data.role || 'AGENT',
        isActive: data.isActive ?? true,
        dateCreated: data.createdAt instanceof Timestamp 
          ? data.createdAt.toDate().toISOString()
          : data.createdAt || new Date().toISOString(),
        lastLogin: data.lastLogin instanceof Timestamp
          ? data.lastLogin.toDate().toISOString()
          : data.lastLogin,
        password: '' // Never return actual password
      });
    });

    return users;
  } catch (error) {
    console.error('Error getting users:', error);
    throw error;
  }
}

export async function createUser(userData: Omit<User, 'id' | 'dateCreated'>): Promise<User> {
  try {
    // Create Firebase Auth user
    const userCredential = await createUserWithEmailAndPassword(auth, userData.email, userData.password);
    const firebaseUser = userCredential.user;

    // Create user profile in Firestore
    const userProfile: Omit<User, 'password'> = {
      id: firebaseUser.uid,
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      role: userData.role || 'AGENT',
      isActive: true,
      dateCreated: new Date().toISOString(),
      lastLogin: new Date().toISOString()
    };

    // Save to Firestore
    await setDoc(doc(db, USERS_COLLECTION, firebaseUser.uid), {
      ...userProfile,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    // Update display name
    await updateProfile(firebaseUser, {
      displayName: `${userData.firstName} ${userData.lastName}`
    });

    // Send password reset email if needed
    if (!userData.password) {
      await sendPasswordResetEmail(auth, userData.email);
    }

    return {
      ...userProfile,
      password: '' // Never return actual password
    } as User;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
}

export async function updateUser(userId: string, userData: Partial<User>): Promise<void> {
  try {
    const userRef = doc(db, USERS_COLLECTION, userId);
    
    const updateData = {
      ...userData,
      updatedAt: serverTimestamp()
    };

    delete updateData.password; // Never store password in Firestore
    await updateDoc(userRef, updateData);

    // Update display name if changed
    const currentUser = auth.currentUser;
    if (currentUser && userData.firstName && userData.lastName) {
      await updateProfile(currentUser, {
        displayName: `${userData.firstName} ${userData.lastName}`
      });
    }
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
}

export async function deleteUserAccount(userId: string): Promise<void> {
  try {
    const userRef = doc(db, USERS_COLLECTION, userId);
    await updateDoc(userRef, {
      isActive: false,
      updatedAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
}

export async function getUserByEmail(email: string): Promise<User | null> {
  try {
    const q = query(collection(db, USERS_COLLECTION), where('email', '==', email));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      const data = doc.data();
      return {
        id: doc.id,
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        email: data.email || '',
        role: data.role || 'AGENT',
        isActive: data.isActive ?? true,
        dateCreated: data.createdAt instanceof Timestamp 
          ? data.createdAt.toDate().toISOString()
          : data.createdAt || new Date().toISOString(),
        lastLogin: data.lastLogin instanceof Timestamp
          ? data.lastLogin.toDate().toISOString()
          : data.lastLogin,
        password: ''
      } as User;
    }
    return null;
  } catch (error) {
    console.error('Error getting user by email:', error);
    throw error;
  }
}