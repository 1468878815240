import React from 'react';
import { DashboardStats } from '../components/dashboard/DashboardStats';
import { DashboardCharts } from '../components/dashboard/DashboardCharts';
import { RecentActivity } from '../components/dashboard/RecentActivity';
import { PerformanceChart } from '../components/dashboard/PerformanceChart';
import { useProspects } from '../hooks/useProspects';
import { PageLoader } from '../components/common/PageLoader';

export function DashboardPage() {
  const { prospects, isLoading } = useProspects();

  if (isLoading) {
    return (
      <PageLoader 
        message="Chargement du tableau de bord" 
        description="Récupération des données et préparation des statistiques..."
      />
    );
  }

  return (
    <div className="max-w-[1920px] mx-auto px-4 py-6">
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Tableau de bord</h1>
        <p className="text-gray-500">Vue d'ensemble de votre activité commerciale</p>
      </div>

      <div className="space-y-5">
        <DashboardStats prospects={prospects} />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          <DashboardCharts prospects={prospects} />
          <PerformanceChart prospects={prospects} />
        </div>
        <RecentActivity prospects={prospects} />
      </div>
    </div>
  );
}