import { useState, useEffect } from 'react';
import { Campaign } from '../types/campaign';
import {
  getAllCampaigns,
  createCampaign as createFirebaseCampaign,
  updateCampaign as updateFirebaseCampaign,
  deleteCampaign as deleteFirebaseCampaign
} from '../services/firebase/campaigns';
import toast from 'react-hot-toast';

export function useCampaigns() {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadCampaigns();
  }, []);

  const loadCampaigns = async () => {
    try {
      const loadedCampaigns = await getAllCampaigns();
      setCampaigns(loadedCampaigns);
      setIsLoading(false);
    } catch (error) {
      console.error('Error initializing campaigns:', error);
      toast.error('Erreur lors du chargement des campagnes');
      setIsLoading(false);
    }
  };

  const createCampaign = async (campaignData: Omit<Campaign, 'id' | 'dateCreated'>) => {
    try {
      const id = await createFirebaseCampaign(campaignData);
      const newCampaign: Campaign = {
        ...campaignData,
        id,
        dateCreated: new Date().toISOString(),
        totalLeads: 0,
        totalProspects: 0
      };
      setCampaigns(prev => [...prev, newCampaign]);
      toast.success('Campagne créée avec succès');
    } catch (error) {
      console.error('Error creating campaign:', error);
      toast.error('Erreur lors de la création de la campagne');
    }
  };

  const updateCampaign = async (campaign: Campaign) => {
    try {
      await updateFirebaseCampaign(campaign);
      setCampaigns(prev => prev.map(c => c.id === campaign.id ? campaign : c));
      toast.success('Campagne mise à jour avec succès');
    } catch (error) {
      console.error('Error updating campaign:', error);
      toast.error('Erreur lors de la mise à jour de la campagne');
    }
  };

  const deleteCampaign = async (id: string) => {
    try {
      await deleteFirebaseCampaign(id);
      setCampaigns(prev => prev.filter(c => c.id !== id));
      toast.success('Campagne supprimée avec succès');
    } catch (error) {
      console.error('Error deleting campaign:', error);
      toast.error('Erreur lors de la suppression de la campagne');
    }
  };

  return {
    campaigns,
    isLoading,
    createCampaign,
    updateCampaign,
    deleteCampaign,
  };
}