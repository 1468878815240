import { initializeApp } from 'firebase/app';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAjeELN9EE57OTDZHJ6Fq9gNTV7EYZr5t4",
  authDomain: "bolt-crm-leads-ia.firebaseapp.com",
  projectId: "bolt-crm-leads-ia",
  storageBucket: "bolt-crm-leads-ia.appspot.com",
  messagingSenderId: "484696684110",
  appId: "1:484696684110:web:b411647543e946c988b51f",
  measurementId: "G-QQG3JXTXSD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Enable offline persistence
if (typeof window !== 'undefined') {
  enableIndexedDbPersistence(db).catch((err) => {
    if (err.code === 'failed-precondition') {
      console.warn('Multiple tabs open, persistence can only be enabled in one tab at a time.');
    } else if (err.code === 'unimplemented') {
      console.warn('The current browser does not support persistence.');
    }
  });
}

// Initialize Auth
const auth = getAuth(app);

export { db, auth };
export default app;