import { 
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  writeBatch,
  query,
  orderBy,
  serverTimestamp,
  Timestamp,
  where,
  limit,
  getDoc,
  arrayUnion
} from 'firebase/firestore';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { db } from './init';
import { Prospect, ProspectHistoryEvent } from '../../types/prospect';
import { STATUS_LABELS } from '../../constants/status';

const PROSPECTS_COLLECTION = 'prospects';
const BATCH_SIZE = 500;

export async function getAllProspects(): Promise<Prospect[]> {
  try {
    const q = query(
      collection(db, PROSPECTS_COLLECTION),
      orderBy('dateCreation', 'desc')
    );
    
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        nom: data.nom || '',
        prenom: data.prenom || '',
        email: data.email || '',
        telephone: data.telephone || '',
        ville: data.ville || '',
        campagne: data.campagne || '',
        dateCreation: data.dateCreation instanceof Timestamp 
          ? data.dateCreation.toDate().toISOString()
          : data.dateCreation || new Date().toISOString(),
        status: data.status || 'NOUVEAU',
        dernierAppel: data.dernierAppel instanceof Timestamp 
          ? data.dernierAppel.toDate().toISOString() 
          : data.dernierAppel || null,
        proprietaire: Boolean(data.proprietaire),
        codePostal: data.codePostal || '',
        departement: data.departement || '',
        modeChauffage: data.modeChauffage || '',
        montantElectricite: Number(data.montantElectricite) || 0,
        revenus: Number(data.revenus) || 0,
        credit: data.credit || '',
        solution: data.solution || '',
        dateSold: data.dateSold instanceof Timestamp 
          ? data.dateSold.toDate().toISOString() 
          : data.dateSold,
        prospectPrice: Number(data.prospectPrice) || 0,
        leadPrice: Number(data.leadPrice) || 0,
        history: Array.isArray(data.history) ? data.history.map(event => ({
          ...event,
          date: event.date instanceof Timestamp 
            ? event.date.toDate().toISOString()
            : event.date
        })) : []
      } as Prospect;
    });
  } catch (error) {
    console.error('Error getting prospects:', error);
    throw error;
  }
}

export async function updateProspect(prospect: Prospect): Promise<void> {
  try {
    const docRef = doc(db, PROSPECTS_COLLECTION, prospect.id);
    const docSnap = await getDoc(docRef);
    const currentData = docSnap.data();
    const { id, history, ...updateData } = prospect;

    // Create history events array
    const historyEvents: ProspectHistoryEvent[] = [];

    // Check for status change
    if (currentData && currentData.status !== updateData.status) {
      historyEvents.push({
        date: new Date().toISOString(),
        type: 'STATUS_CHANGE',
        previousValue: STATUS_LABELS[currentData.status],
        newValue: STATUS_LABELS[updateData.status],
        details: `Passé au statut ${STATUS_LABELS[updateData.status]}`
      });
    }

    // Check for campaign change
    if (currentData && currentData.campagne !== updateData.campagne) {
      historyEvents.push({
        date: new Date().toISOString(),
        type: 'CAMPAIGN_CHANGE',
        previousValue: currentData.campagne || 'Aucune',
        newValue: updateData.campagne,
        details: `Campagne modifiée: ${updateData.campagne}`
      });
    }

    // Check for lead price change
    if (currentData && currentData.leadPrice !== updateData.leadPrice) {
      historyEvents.push({
        date: new Date().toISOString(),
        type: 'PRICE_CHANGE',
        previousValue: `${currentData.leadPrice || 0}€`,
        newValue: `${updateData.leadPrice}€`,
        details: `Prix lead modifié: ${updateData.leadPrice}€`
      });
    }

    // Check for call
    if (currentData && currentData.dernierAppel !== updateData.dernierAppel && updateData.dernierAppel) {
      historyEvents.push({
        date: new Date().toISOString(),
        type: 'CALL',
        newValue: format(new Date(updateData.dernierAppel), 'dd/MM/yyyy HH:mm', { locale: fr }),
        details: 'Appel effectué'
      });
    }

    // Check for sale
    if (updateData.status === 'VENDU' && (!currentData || currentData.status !== 'VENDU')) {
      historyEvents.push({
        date: new Date().toISOString(),
        type: 'SALE',
        newValue: `${updateData.leadPrice}€`,
        details: `Vendu pour ${updateData.leadPrice}€`
      });
    }

    // Convert dates to Firestore Timestamps
    const historyEventsForFirestore = historyEvents.map(event => ({
      ...event,
      date: Timestamp.fromDate(new Date(event.date))
    }));

    const dataToUpdate = {
      ...updateData,
      updatedAt: serverTimestamp(),
      dernierAppel: updateData.dernierAppel ? Timestamp.fromDate(new Date(updateData.dernierAppel)) : null,
      dateSold: updateData.dateSold ? Timestamp.fromDate(new Date(updateData.dateSold)) : null,
      montantElectricite: Number(updateData.montantElectricite) || 0,
      revenus: Number(updateData.revenus) || 0,
      prospectPrice: Number(updateData.prospectPrice) || 0,
      leadPrice: Number(updateData.leadPrice) || 0,
      proprietaire: Boolean(updateData.proprietaire)
    };

    // Use arrayUnion to add new history events
    if (historyEventsForFirestore.length > 0) {
      await updateDoc(docRef, {
        ...dataToUpdate,
        history: arrayUnion(...historyEventsForFirestore)
      });
    } else {
      await updateDoc(docRef, dataToUpdate);
    }
  } catch (error) {
    console.error('Error updating prospect:', error);
    throw error;
  }
}

export async function addProspects(prospects: Prospect[]): Promise<void> {
  try {
    const batches = [];
    
    for (let i = 0; i < prospects.length; i += BATCH_SIZE) {
      const batch = writeBatch(db);
      const batchProspects = prospects.slice(i, i + BATCH_SIZE);
      
      for (const prospect of batchProspects) {
        const docRef = doc(collection(db, PROSPECTS_COLLECTION));
        const prospectData = {
          ...prospect,
          dateCreation: serverTimestamp(),
          updatedAt: serverTimestamp(),
          dernierAppel: prospect.dernierAppel ? Timestamp.fromDate(new Date(prospect.dernierAppel)) : null,
          dateSold: prospect.dateSold ? Timestamp.fromDate(new Date(prospect.dateSold)) : null,
          montantElectricite: Number(prospect.montantElectricite) || 0,
          revenus: Number(prospect.revenus) || 0,
          prospectPrice: Number(prospect.prospectPrice) || 0,
          leadPrice: Number(prospect.leadPrice) || 0,
          proprietaire: Boolean(prospect.proprietaire),
          history: [{
            date: Timestamp.fromDate(new Date()),
            type: 'CREATION',
            newValue: 'Prospect créé',
            details: 'Création du prospect'
          }]
        };
        delete prospectData.id;
        batch.set(docRef, prospectData);
      }
      
      batches.push(batch.commit());
    }
    
    await Promise.all(batches);
  } catch (error) {
    console.error('Error adding prospects:', error);
    throw error;
  }
}

export async function deleteProspects(ids: string[]): Promise<void> {
  try {
    const batches = [];
    
    for (let i = 0; i < ids.length; i += BATCH_SIZE) {
      const batch = writeBatch(db);
      const batchIds = ids.slice(i, i + BATCH_SIZE);
      
      for (const id of batchIds) {
        const docRef = doc(db, PROSPECTS_COLLECTION, id);
        batch.delete(docRef);
      }
      
      batches.push(batch.commit());
    }
    
    await Promise.all(batches);
  } catch (error) {
    console.error('Error deleting prospects:', error);
    throw error;
  }
}