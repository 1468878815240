import { useState, useEffect } from 'react';
import { Prospect } from '../types/prospect';
import { 
  getAllProspects,
  addProspects as addFirebaseProspects,
  deleteProspects as deleteFirebaseProspects,
  updateProspect as updateFirebaseProspect
} from '../services/firebase/prospects';
import { getAllCampaigns } from '../services/firebase/campaigns';
import toast from 'react-hot-toast';

export function useProspects() {
  const [prospects, setProspects] = useState<Prospect[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [importProgress, setImportProgress] = useState<{
    isImporting: boolean;
    current: number;
    total: number;
    isComplete: boolean;
  }>({
    isImporting: false,
    current: 0,
    total: 0,
    isComplete: false
  });

  useEffect(() => {
    loadProspects();
  }, []);

  const loadProspects = async () => {
    try {
      const [loadedProspects, campaigns] = await Promise.all([
        getAllProspects(),
        getAllCampaigns()
      ]);

      const updatedProspects = loadedProspects.map(prospect => {
        const campaign = campaigns.find(c => c.id === prospect.campagne);
        if (campaign) {
          return {
            ...prospect,
            prospectPrice: campaign.pricePerProspect || 0,
            leadPrice: prospect.status === 'VENDU' ? (campaign.pricePerLead || 0) : prospect.leadPrice
          };
        }
        return prospect;
      });

      setProspects(updatedProspects);
    } catch (error) {
      console.error('Erreur lors du chargement des prospects:', error);
      toast.error('Erreur lors du chargement des prospects');
    } finally {
      setIsLoading(false);
    }
  };

  const handleImportCSV = async (data: Partial<Prospect>[], campaignId: string) => {
    try {
      const campaigns = await getAllCampaigns();
      const campaign = campaigns.find(c => c.id === campaignId);
      
      if (!campaign) {
        throw new Error('Campagne non trouvée');
      }

      setImportProgress({
        isImporting: true,
        current: 0,
        total: data.length,
        isComplete: false
      });

      // Process prospects in batches of 100
      const batchSize = 100;
      for (let i = 0; i < data.length; i += batchSize) {
        const batch = data.slice(i, i + batchSize);
        const newProspects = batch.map(prospect => ({
          ...prospect,
          id: crypto.randomUUID(),
          campagne: campaignId,
          dateCreation: new Date().toISOString(),
          proprietaire: prospect.proprietaire || false,
          montantElectricite: prospect.montantElectricite || 0,
          revenus: prospect.revenus || 0,
          credit: prospect.credit || false,
          prospectPrice: campaign.pricePerProspect || 0,
          leadPrice: 0
        } as Prospect));

        await addFirebaseProspects(newProspects);
        
        setImportProgress(prev => ({
          ...prev,
          current: Math.min(prev.current + batch.length, data.length)
        }));
      }

      await loadProspects();
      
      setImportProgress(prev => ({
        ...prev,
        isComplete: true
      }));

      // Reset import progress after a delay
      setTimeout(() => {
        setImportProgress({
          isImporting: false,
          current: 0,
          total: 0,
          isComplete: false
        });
      }, 2000);

    } catch (error) {
      console.error('Erreur lors de l\'import:', error);
      toast.error('Erreur lors de l\'import CSV');
      setImportProgress({
        isImporting: false,
        current: 0,
        total: 0,
        isComplete: false
      });
    }
  };

  const handleDeleteProspects = async (selectedIds: Set<string>) => {
    try {
      await deleteFirebaseProspects(Array.from(selectedIds));
      setProspects(prev => prev.filter(p => !selectedIds.has(p.id)));
      toast.success(`${selectedIds.size} prospect(s) supprimé(s)`);
    } catch (error) {
      console.error('Erreur lors de la suppression:', error);
      toast.error('Erreur lors de la suppression des prospects');
    }
  };

  const handleUpdateProspect = async (updatedProspect: Prospect) => {
    try {
      await updateFirebaseProspect(updatedProspect);
      setProspects(prev => prev.map(p => 
        p.id === updatedProspect.id ? updatedProspect : p
      ));
      toast.success('Prospect mis à jour avec succès');
    } catch (error) {
      console.error('Erreur lors de la mise à jour:', error);
      toast.error('Erreur lors de la mise à jour du prospect');
    }
  };

  return {
    prospects,
    isLoading,
    importProgress,
    importProspects: handleImportCSV,
    deleteProspects: handleDeleteProspects,
    updateProspect: handleUpdateProspect
  };
}