import React, { useState } from 'react';
import { X } from 'lucide-react';
import { motion } from 'framer-motion';

interface ExportFieldsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (selectedFields: string[]) => void;
}

// Champs par défaut
const DEFAULT_FIELDS = [
  { value: 'nom', label: 'Nom' },
  { value: 'prenom', label: 'Prénom' },
  { value: 'telephone', label: 'Téléphone' },
  { value: 'departement', label: 'Département' },
  { value: 'montantElectricite', label: 'Montant électricité' },
  { value: 'credit', label: 'Crédit' },
  { value: 'modeChauffage', label: 'Mode de chauffage' },
  { value: 'revenus', label: 'Revenus' },
  { value: 'solution', label: 'Solution' }
];

// Champs additionnels
const ADDITIONAL_FIELDS = [
  { value: 'email', label: 'Email' },
  { value: 'ville', label: 'Ville' },
  { value: 'codePostal', label: 'Code postal' },
  { value: 'proprietaire', label: 'Propriétaire' },
  { value: 'status', label: 'Statut' },
  { value: 'campagne', label: 'Campagne' },
  { value: 'dateCreation', label: 'Date de création' },
  { value: 'dateSold', label: 'Date de vente' },
  { value: 'dernierAppel', label: 'Dernier appel' }
];

export function ExportFieldsModal({
  isOpen,
  onClose,
  onConfirm
}: ExportFieldsModalProps) {
  // Initialiser avec les champs par défaut
  const [selectedFields, setSelectedFields] = useState<Set<string>>(
    new Set(DEFAULT_FIELDS.map(f => f.value))
  );

  if (!isOpen) return null;

  const handleToggleField = (value: string) => {
    const newSelected = new Set(selectedFields);
    if (newSelected.has(value)) {
      newSelected.delete(value);
    } else {
      newSelected.add(value);
    }
    setSelectedFields(newSelected);
  };

  const handleSelectAll = () => {
    setSelectedFields(new Set([
      ...DEFAULT_FIELDS.map(f => f.value),
      ...ADDITIONAL_FIELDS.map(f => f.value)
    ]));
  };

  const handleDeselectAll = () => {
    setSelectedFields(new Set());
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-white rounded-xl w-full max-w-lg mx-4 shadow-xl"
      >
        <div className="p-4 border-b flex items-center justify-between">
          <h2 className="text-lg font-semibold text-gray-900">
            Sélectionner les champs à exporter
          </h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
          >
            <X className="h-5 w-5 text-gray-500" />
          </button>
        </div>

        <div className="p-6">
          <div className="flex justify-end gap-2 mb-4">
            <button
              onClick={handleSelectAll}
              className="text-sm text-[#2665EB] hover:text-[#1b4bbd]"
            >
              Tout sélectionner
            </button>
            <button
              onClick={handleDeselectAll}
              className="text-sm text-gray-500 hover:text-gray-600"
            >
              Tout désélectionner
            </button>
          </div>

          <div className="space-y-4">
            <div>
              <h3 className="text-sm font-medium text-gray-700 mb-2">Champs par défaut</h3>
              <div className="grid grid-cols-2 gap-2">
                {DEFAULT_FIELDS.map(({ value, label }) => (
                  <label
                    key={value}
                    className="flex items-center gap-2 p-2 rounded hover:bg-gray-50 cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      checked={selectedFields.has(value)}
                      onChange={() => handleToggleField(value)}
                      className="rounded border-gray-300 text-[#2665EB] focus:ring-[#2665EB]"
                    />
                    <span className="text-sm text-gray-700">{label}</span>
                  </label>
                ))}
              </div>
            </div>

            <div>
              <h3 className="text-sm font-medium text-gray-700 mb-2">Champs additionnels</h3>
              <div className="grid grid-cols-2 gap-2">
                {ADDITIONAL_FIELDS.map(({ value, label }) => (
                  <label
                    key={value}
                    className="flex items-center gap-2 p-2 rounded hover:bg-gray-50 cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      checked={selectedFields.has(value)}
                      onChange={() => handleToggleField(value)}
                      className="rounded border-gray-300 text-[#2665EB] focus:ring-[#2665EB]"
                    />
                    <span className="text-sm text-gray-700">{label}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 border-t flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors"
          >
            Annuler
          </button>
          <button
            onClick={() => {
              onConfirm(Array.from(selectedFields));
              onClose();
            }}
            disabled={selectedFields.size === 0}
            className="px-4 py-2 text-sm font-medium text-white bg-[#2665EB] hover:bg-[#1b4bbd] rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Exporter {selectedFields.size} champ{selectedFields.size > 1 ? 's' : ''}
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
}