import { useState, useEffect } from 'react';
import { User } from '../types/user';
import { 
  getAllUsers,
  createUser as createFirebaseUser,
  updateUser as updateFirebaseUser,
  deleteUserAccount as deleteFirebaseUser
} from '../services/firebase/users';
import toast from 'react-hot-toast';

export function useUsers() {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    try {
      const loadedUsers = await getAllUsers();
      setUsers(loadedUsers);
    } catch (error) {
      console.error('Error loading users:', error);
      toast.error('Erreur lors du chargement des utilisateurs');
    } finally {
      setIsLoading(false);
    }
  };

  const createUser = async (userData: Omit<User, 'id' | 'dateCreated'>) => {
    try {
      const newUser = await createFirebaseUser(userData);
      setUsers(prev => [...prev, newUser]);
      toast.success('Utilisateur créé avec succès');
      return newUser;
    } catch (error: any) {
      console.error('Error creating user:', error);
      toast.error(error.message || 'Erreur lors de la création de l\'utilisateur');
      throw error;
    }
  };

  const updateUser = async (userId: string, userData: Partial<User>) => {
    try {
      await updateFirebaseUser(userId, userData);
      setUsers(prev => prev.map(user => 
        user.id === userId ? { ...user, ...userData } : user
      ));
      toast.success('Utilisateur mis à jour avec succès');
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error('Erreur lors de la mise à jour de l\'utilisateur');
      throw error;
    }
  };

  const deleteUser = async (userId: string) => {
    try {
      await deleteFirebaseUser(userId);
      setUsers(prev => prev.filter(user => user.id !== userId));
      toast.success('Utilisateur supprimé avec succès');
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error('Erreur lors de la suppression de l\'utilisateur');
      throw error;
    }
  };

  return {
    users,
    isLoading,
    createUser,
    updateUser,
    deleteUser,
  };
}